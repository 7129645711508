import _reachUtilsCjsProd from "./reach-utils.cjs.prod.js";
export { _reachUtilsCjsProd as default };
export const __esModule = _reachUtilsCjsProd.__esModule,
      checkStyles = _reachUtilsCjsProd.checkStyles,
      useControlledSwitchWarning = _reachUtilsCjsProd.useControlledSwitchWarning,
      useCheckStyles = _reachUtilsCjsProd.useCheckStyles,
      useStateLogger = _reachUtilsCjsProd.useStateLogger,
      assignRef = _reachUtilsCjsProd.assignRef,
      boolOrBoolString = _reachUtilsCjsProd.boolOrBoolString,
      canUseDOM = _reachUtilsCjsProd.canUseDOM,
      cloneValidElement = _reachUtilsCjsProd.cloneValidElement,
      createNamedContext = _reachUtilsCjsProd.createNamedContext,
      forwardRefWithAs = _reachUtilsCjsProd.forwardRefWithAs,
      getDocumentDimensions = _reachUtilsCjsProd.getDocumentDimensions,
      getElementComputedStyle = _reachUtilsCjsProd.getElementComputedStyle,
      getOwnerDocument = _reachUtilsCjsProd.getOwnerDocument,
      getOwnerWindow = _reachUtilsCjsProd.getOwnerWindow,
      getScrollPosition = _reachUtilsCjsProd.getScrollPosition,
      getScrollbarOffset = _reachUtilsCjsProd.getScrollbarOffset,
      isBoolean = _reachUtilsCjsProd.isBoolean,
      isFunction = _reachUtilsCjsProd.isFunction,
      isNumber = _reachUtilsCjsProd.isNumber,
      isRightClick = _reachUtilsCjsProd.isRightClick,
      isString = _reachUtilsCjsProd.isString,
      makeId = _reachUtilsCjsProd.makeId,
      memoWithAs = _reachUtilsCjsProd.memoWithAs,
      noop = _reachUtilsCjsProd.noop,
      ponyfillGlobal = _reachUtilsCjsProd.ponyfillGlobal,
      stateToAttributeString = _reachUtilsCjsProd.stateToAttributeString,
      useCallbackProp = _reachUtilsCjsProd.useCallbackProp,
      useConstant = _reachUtilsCjsProd.useConstant,
      useControlledState = _reachUtilsCjsProd.useControlledState,
      useEventCallback = _reachUtilsCjsProd.useEventCallback,
      useEventListener = _reachUtilsCjsProd.useEventListener,
      useFocusChange = _reachUtilsCjsProd.useFocusChange,
      useForceUpdate = _reachUtilsCjsProd.useForceUpdate,
      useForkedRef = _reachUtilsCjsProd.useForkedRef,
      useIsomorphicLayoutEffect = _reachUtilsCjsProd.useIsomorphicLayoutEffect,
      useLazyRef = _reachUtilsCjsProd.useLazyRef,
      usePrevious = _reachUtilsCjsProd.usePrevious,
      useStableCallback = _reachUtilsCjsProd.useStableCallback,
      useUpdateEffect = _reachUtilsCjsProd.useUpdateEffect,
      warning = _reachUtilsCjsProd.warning,
      wrapEvent = _reachUtilsCjsProd.wrapEvent;